"use client";

import React from "react";
import { useCookies } from "next-client-cookies";
import { GlobalOutlined } from "@ant-design/icons";
import { segmentEvents } from "@/src/constants/segmentEvents";
import type { MenuProps } from "@/src/pattern-library";
import { Button, Dropdown } from "@/src/pattern-library";
import { segmentClient } from "@/src/utils/segment";
import { UserLanguage } from "@/src/enums/common";
import useAppContext from "@/src/components/appContext/useAppContext";
const items: MenuProps["items"] = [{
  label: "English",
  key: UserLanguage.EN
}, {
  label: "日本語",
  key: UserLanguage.JP
}, {
  label: "한국어",
  key: UserLanguage.KO
}, {
  label: "ไทย",
  key: UserLanguage.TH
}, {
  label: "中文(简体)",
  key: UserLanguage.ZH
}];
const AppLanguageSelection = () => {
  const cookies = useCookies();
  const {
    ctx
  } = useAppContext();
  const value = ctx.lang;
  const handleChange = (val: {
    key: string;
  }) => {
    cookies.set("lang", val.key, {
      expires: 30 * 24 * 60 * 60
    });
    segmentClient.track(segmentEvents.languageSet, {
      previous_lang: value,
      selected_lang: val.key
    }, undefined, () => {
      window.location.reload();
    });
  };
  return <>
      <Dropdown menu={{
      items,
      onClick: handleChange
    }} data-sentry-element="Dropdown" data-sentry-source-file="AppLanguageSelection.tsx">
        <Button shape="round" icon={<GlobalOutlined />} data-sentry-element="Button" data-sentry-source-file="AppLanguageSelection.tsx">
          {(items.find(item => item?.key === value) as {
          label: string;
        })?.label}
        </Button>
      </Dropdown>
    </>;
};
export default AppLanguageSelection;